import { useDispatch, useSelector } from 'react-redux';

import {
  DataGridPro,
  GridCallbackDetails,
  GridSortModel,
  GridSelectionModel,
  GridFilterModel,
} from '@mui/x-data-grid-pro';

import { IRootState } from 'src/store';
import {
  setPage,
  setLoadingEffect,
  setSelectedCheckboxes,
  setSortModel,
  tasksGetRequest,
  setTasksFilterModel,
} from 'src/store/dashboard/slices/tasksSlice';

import TaskFooter from '../Others/TaskFooter';
import { getColumns } from './Columns';
import TasksToolbar from '../Others/TasksToolbar';
import { getTasksFilterModelShaped } from 'src/pages/dashboard/Tasks/helpers/getTasksFilterModelShaped';
import EmptyContent from 'src/components/empty-content';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import {
  dataGridFilterControlsForRequest,
  queryFilterItemControl,
} from 'src/utils/datagridFilterControlsForRequest';
import { useQueryParamsForDatagrid } from 'src/hooks/useQueryParamsForDatagrid';
import { useMemo } from 'react';
import { Marketplace } from '@beta.limited/primelister';

export default function TasksDataGrid() {
  const dispatch = useDispatch();
  const {
    dense,
    tasks,
    taskCount,
    page,
    loadingEffect,
    rowsPerPage,
    selectedCheckboxes,
    sortModel,
    selectedTab,
    tasksFilterModel,
    loading,
  } = useSelector((state: IRootState) => state.tasks);
  const [searchParams, setSearchParams] = useSearchParams();

  const allShops = useSelector((state: IRootState) => state.myShopsAPITable.allShops) || [];
  useQueryParamsForDatagrid({
    datagrid: 'tasks',
    tasksFilterModel: tasksFilterModel,
    tasksPage: page,
    tasksRowsPerPage: rowsPerPage,
    tasksSortModel: sortModel,
    selectedTab: selectedTab,
  });

  // Handles page change and fetchs new data from the API.
  const handlePageChange = (pageNumber: number) => {
    searchParams.set('page', (pageNumber + 1).toString());
    setSearchParams(searchParams);
    dispatch(setPage(pageNumber));
    dispatch(setLoadingEffect(true));
    const [sortField, sort] =
      sortModel.length > 0 ? [sortModel[0].field, sortModel[0].sort] : ['added', 'desc'];
    const sortOptions = { orderBy: sortField!, order: sort! };
    dispatch(
      tasksGetRequest({
        page: pageNumber,
        rowsPerPage,
        filter: getTasksFilterModelShaped(tasksFilterModel),
        selectedTab,
        ...sortOptions,
      })
    );
  };

  // Handles the sort model changes and fetchs new data from the API.
  const handleSortModelChange = (model: GridSortModel) => {
    const orderBy = model.length > 0 ? model[0].field : 'createdDate';
    const order = model.length > 0 ? model[0].sort : 'desc';
    searchParams.set('order', order as string);
    searchParams.set('orderBy', orderBy);
    setSearchParams(searchParams);

    dispatch(setLoadingEffect(true));
    dispatch(
      tasksGetRequest({
        page,
        rowsPerPage,
        orderBy,
        order: order!,
        filter: getTasksFilterModelShaped(tasksFilterModel),
        selectedTab,
      })
    );

    dispatch(setSortModel(model));
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    dispatch(setTasksFilterModel(model));
    const queryFilterModel = {
      ...model,
      items: model.items.filter((item) => queryFilterItemControl(item)),
    };
    if (dataGridFilterControlsForRequest(model)) {
      dispatch(setPage(0));
      dispatch(setSelectedCheckboxes([]));
      const [sortField, sort] =
        sortModel.length > 0 ? [sortModel[0].field, sortModel[0].sort] : ['added', 'desc'];

      const sortOptions = { orderBy: sortField!, order: sort! };
      const shouldAddFilterToQuery = model.items.length && queryFilterModel.items.length;

      if (shouldAddFilterToQuery) {
        searchParams.set('filters', JSON.stringify(queryFilterModel));
        setSearchParams(searchParams);
      } else {
        searchParams.delete('filters');
        setSearchParams(searchParams);
      }
      dispatch(
        tasksGetRequest({
          page: 0,
          rowsPerPage,
          filter: getTasksFilterModelShaped(model),
          selectedTab,
          ...sortOptions,
        })
      );
    } else {
      if (queryFilterModel.items.length) {
        searchParams.set('filters', JSON.stringify(queryFilterModel));
        setSearchParams(searchParams);
      } else {
        searchParams.delete('filters');
        setSearchParams(searchParams);
      }
    }
  };

  const handleSelectedCheckboxes = (checkboxes: GridSelectionModel) => {
    dispatch(setSelectedCheckboxes(checkboxes as Array<string>));
  };

  const dataGridComponentProps = {
    filterPanel: {
      sx: {
        '& .MuiDataGrid-filterFormValueInput': {
          minWidth: 'max-content !important',
        },
      },
    },
  };

  const dataGridHeight = 'calc(100vh - 174px)';

  const memoizedTasks = useMemo(
    () =>
      tasks.length > 0
        ? tasks.map((task) => ({
            id: task._id.toString(),
            image: { image: task.image },
            name: { name: task.name },
            typeName: { typeName: task.typeName },
            marketplace: { marketplace: task.marketplace },
            taskResult: { result: task.taskResult ? task.taskResult : '' },
            createdDate: {
              createdDate: task.createdDate,
            },
            executeAt: {
              executeAt: task.executeAt ?? 1,
            },
            actions: {
              id: task._id,
              name: task.name,
              link: task.link,
              result: task.taskResult,
              errorText: task.errorText !== null ? task.errorText : '',
            },
          }))
        : [],
    [tasks]
  );

  return (
    <Box sx={{ height: dataGridHeight }}>
      <DataGridPro
        density={dense ? 'compact' : 'comfortable'}
        loading={loadingEffect || loading}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        page={page}
        pageSize={rowsPerPage}
        getEstimatedRowHeight={() => 500}
        getRowHeight={() => 'auto'}
        rowsPerPageOptions={[25, 50, 100, 250, 500]}
        components={{
          Footer: TaskFooter,
          Toolbar: TasksToolbar,
          NoRowsOverlay: () => <EmptyContent title="No results found." />,
          NoResultsOverlay: () => <EmptyContent title="No results found." />,
        }}
        componentsProps={dataGridComponentProps}
        rows={memoizedTasks}
        headerHeight={dense ? 59 : 43.6}
        rowCount={taskCount}
        columns={getColumns(allShops.map((shop) => shop.marketplace as Marketplace))}
        checkboxSelection={false}
        disableSelectionOnClick={true}
        disableColumnSelector={true}
        sortModel={sortModel}
        filterModel={tasksFilterModel}
        disableColumnResize={true}
        keepNonExistentRowsSelected={true}
        onPageChange={(pageNumber: number, details: GridCallbackDetails) =>
          handlePageChange(pageNumber)
        }
        onSortModelChange={(model: GridSortModel, details: GridCallbackDetails) =>
          handleSortModelChange(model)
        }
        onSelectionModelChange={(
          selectionModel: GridSelectionModel,
          details: GridCallbackDetails
        ) => handleSelectedCheckboxes(selectionModel)}
        onFilterModelChange={(model: GridFilterModel, details: GridCallbackDetails) => {
          handleFilterModelChange(model);
        }}
        selectionModel={selectedCheckboxes}
        sx={{
          '& .MuiDataGrid-cell': {
            whiteSpace: 'normal !important',
            wordBreak: 'break-word !important',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#f3f6f8',
            borderRadius: 0,
          },
          '& .MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: '#f3f6f8 !important',
            borderRadius: 0,
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { px: '3px', py: '2px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { p: '6px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { px: '13px', py: '7.5px' },
          '&.MuiDataGrid-root .MuiDataGrid-virtualScrollerRenderZone': {
            ...(selectedCheckboxes.length > 0 && { pb: '96px' }),
          },
          '& .MuiDataGrid-columnSeparator': {
            color: '#00000010 !important',
          },
        }}
      />
    </Box>
  );
}
