import {
  Alert,
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Iconify from 'src/components/iconify';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { Automation } from '@beta.limited/primelister';
import CategoryRichTreeView from 'src/pages/automations/MyListingsPage/components/shared/CategoryRichTreeView';
import { getCurrencyInfo } from 'src/pages/automations/AutomationsPage/helpers/getCurrencyInfo';

interface SelectedCategory {
  department: string;
  category?: string;
  subcategory?: string;
}

const PartyShareOptions = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');

  const {
    selectedAutomationOption,
    automationsSettings,
    isAutomationOptionDrawerOpen,
    activeClosetAutomationPlatform,
    loading,
  } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const [isOptionsExpanded, setIsOptionsExpanded] = useState(true);
  const onChangeTimer = React.useRef<any>();
  const onChangeAlertTimer = React.useRef<any>();

  const [shareContinuouslyEnabled, setShareContinuouslyEnabled] = useState(true);
  const [shareSelectedCategoriesEnabled, setShareSelectedCategoriesEnabled] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<SelectedCategory[]>([]);

  const initialPriceRangeEnabled =
    automationsSettings[Automation.AUTO_PARTY_SHARE]?.config.filters.priceRange.enabled || false;

  const initialPriceRangeMin =
    automationsSettings[Automation.AUTO_PARTY_SHARE]?.config.filters.priceRange.min ?? 1;

  const initialPriceRangeMax =
    automationsSettings[Automation.AUTO_PARTY_SHARE]?.config.filters.priceRange.max ?? 2;

  const validationSchema = Yup.object().shape({
    initialPriceRangeMin: Yup.number()
      .required('Please enter a number that is between 0 and 99999.')
      .min(0, 'Please enter a number that is between 0 and 99999.')
      .max(99999, 'Please enter a number that is between 0 and 99999.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
    initialPriceRangeMax: Yup.number()
      .required('Please enter a number that is between 0 and 99999.')
      .min(0, 'Please enter a number that is between 0 and 99999.')
      .max(99999, 'Please enter a number that is between 0 and 99999.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
  });

  const { currency } = getCurrencyInfo(activeClosetAutomationPlatform);

  const handlePartyShareFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      initialPriceRangeEnabled,
      initialPriceRangeMin,
      initialPriceRangeMax,
    },
    validationSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    const config = automationsSettings[Automation.AUTO_PARTY_SHARE]?.config;
    if (config) {
      setShareContinuouslyEnabled(config.shareContinuously ?? true);
      setShareSelectedCategoriesEnabled(config.filters?.category?.enabled ?? false);
      setSelectedCategories(
        (config.filters?.category?.selectedCategories ?? []).filter(
          (cat): cat is SelectedCategory => typeof cat.department === 'string'
        )
      );
    }
  }, [automationsSettings]);

  const handlePriceRangeEnableChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => {
    if (hasActiveClosetConnection) {
      handlePartyShareFormik.setFieldValue('initialPriceRangeEnabled', newValue);
      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_PARTY_SHARE]: {
            config: {
              filters: {
                priceRange: {
                  enabled: newValue,
                },
              },
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };
      dispatch(updateAutomationSettingsRequest(dataForUpdating));
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  const renderAlert = () => {
    const minValue = handlePartyShareFormik.values.initialPriceRangeMin;
    const maxValue = handlePartyShareFormik.values.initialPriceRangeMax;
    let errorMessage = '';

    if (minValue === null || maxValue === null) {
      errorMessage = 'Both minimum and maximum price values are required.';
    } else if (minValue > maxValue) {
      errorMessage = 'Minimum price should be less than or equal to maximum price.';
    } else if (minValue < 0 || maxValue < 0 || minValue > 99999 || maxValue > 99999) {
      errorMessage = 'Please enter whole numbers between 0 and 99999 for both fields.';
    }

    if (errorMessage) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: '#F4F6F8',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
            marginLeft: matches ? '48px !important' : '20px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {errorMessage}
        </Alert>
      );
    }

    return null;
  };

  const pendingPriceUpdates = React.useRef<{ min: number | null; max: number | null }>({
    min: null,
    max: null,
  });

  const handlePriceValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: 'min' | 'max'
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      const formikField = field === 'min' ? 'initialPriceRangeMin' : 'initialPriceRangeMax';
      const inputValue = event.target.value === '' ? null : Number(event.target.value);

      const otherField = field === 'min' ? 'max' : 'min';
      const otherValue =
        field === 'min'
          ? handlePartyShareFormik.values.initialPriceRangeMax
          : handlePartyShareFormik.values.initialPriceRangeMin;

      handlePartyShareFormik.setFieldValue(formikField, inputValue);

      if (inputValue !== null) {
        const isValidInput = inputValue >= 0 && inputValue < 100000 && Number.isInteger(inputValue);
        const isOtherValueValid = otherValue !== null && otherValue >= 0 && otherValue < 100000 && Number.isInteger(otherValue);

        if (isValidInput && isOtherValueValid) {
          const isValidRange = field === 'min' ? inputValue <= otherValue : inputValue >= otherValue;

          if (isValidRange) {
            pendingPriceUpdates.current = {
              min: field === 'min' ? inputValue : otherValue,
              max: field === 'max' ? inputValue : otherValue,
            };

            onChangeTimer.current = setTimeout(() => {
              const { min, max } = pendingPriceUpdates.current;

              if (min !== null && max !== null && min <= max && min >= 0 && max >= 0) {
                const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;

                const dataForUpdating: IDataForUpdateAutomationSettings = {
                  settings: {
                    [Automation.AUTO_PARTY_SHARE]: {
                      config: {
                        filters: {
                          priceRange: pendingPriceUpdates.current,
                        },
                      },
                    },
                  },
                  toastMessage,
                  displayName: selectedAutomationOption.displayName,
                  activeClosetAutomationPlatform,
                  activeClosetCredentialsId,
                };

                dispatch(updateAutomationSettingsRequest(dataForUpdating));
              }
            }, 700);
          }
        }
      }
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  useEffect(
    () => () => {
      clearTimeout(onChangeTimer.current);
    },
    []
  );

  useEffect(() => {
    handlePartyShareFormik.resetForm();
  }, [isAutomationOptionDrawerOpen]);

  const handleOptionChange =
    (optionName: string) => (event: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
      if (hasActiveClosetConnection) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        let dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {},
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        if (optionName === 'shareContinuously') {
          dataForUpdating.settings = {
            [Automation.AUTO_PARTY_SHARE]: {
              config: {
                shareContinuously: newValue,
              },
            },
          };
          setShareContinuouslyEnabled(newValue);
        } else if (optionName === 'shareSelectedCategories') {
          dataForUpdating.settings = {
            [Automation.AUTO_PARTY_SHARE]: {
              config: {
                filters: {
                  category: {
                    enabled: newValue,
                    selectedCategories: newValue ? selectedCategories : [],
                  },
                },
              },
            },
          };
          setShareSelectedCategoriesEnabled(newValue);
        }

        dispatch(updateAutomationSettingsRequest(dataForUpdating));
      } else {
        dispatch(setIsConnectionErrorDialogOpen(true));
      }
    };

  const handleCategoryChange = (newSelectedCategories: SelectedCategory[]) => {
    setSelectedCategories(newSelectedCategories);
    const dataForUpdating: IDataForUpdateAutomationSettings = {
      settings: {
        [Automation.AUTO_PARTY_SHARE]: {
          config: {
            filters: {
              category: {
                enabled: true,
                selectedCategories: newSelectedCategories,
              },
            },
          },
        },
      },
      toastMessage: `${selectedAutomationOption.displayName} automation configurations successfully updated`,
      displayName: selectedAutomationOption.displayName,
      activeClosetAutomationPlatform,
      activeClosetCredentialsId,
    };
    dispatch(updateAutomationSettingsRequest(dataForUpdating));
  };

  const { categoriesLoading } = useSelector((state: IRootState) => state.automations);

  const renderOptionSwitch = (
    optionName: string,
    label: string,
    tooltip: string,
    checked: boolean
  ) => (
    <Stack direction="row" alignItems="center">
      <FormControlLabel
        checked={checked}
        control={
          <Switch
            disabled={loading}
            name={optionName}
            value={checked}
            onChange={handleOptionChange(optionName)}
          />
        }
        label={
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              maxWidth: '280px',
            }}
          >
            <Box>{label}</Box>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title={tooltip}
              placement="top"
              sx={{ textAlign: 'center !important' }}
            >
              <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 1 }} />
            </Tooltip>
          </Stack>
        }
      />
    </Stack>
  );

  const renderPriceRangeSwitch = () =>
    isOptionsExpanded && (
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          marginTop: '8px !important',
        }}
      >
        <FormControlLabel
          checked={handlePartyShareFormik.values.initialPriceRangeEnabled}
          key={'initialPriceRangeEnabled'}
          control={
            <Switch
              disabled={loading}
              name={'initialPriceRangeEnabled'}
              value={handlePartyShareFormik.values.initialPriceRangeEnabled}
              onChange={handlePriceRangeEnableChange}
            />
          }
          label={
            <Stack direction="row" alignItems="center">
              Filter by current price of listing
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                arrow
                title="ℹ️ Set the automation to share only the listings within the specified price range."
                placement={'top'}
                sx={{ textAlign: 'center !important' }}
              >
                <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
              </Tooltip>
            </Stack>
          }
        />
      </Stack>
    );

  const renderPriceRangeInputFields = () =>
    isOptionsExpanded &&
    handlePartyShareFormik.values.initialPriceRangeEnabled && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            marginLeft: matches ? '48px !important' : '20px !important',
            fontSize: '14px !important',
            marginTop: '12px !important',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2">From</Typography>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title="ℹ️ Please enter a whole number between 0-99999."
              placement="top"
            >
              <TextField
                disabled={loading}
                type="number"
                name="initialPriceRangeMin"
                sx={{
                  width: '66px !important',
                  '& .MuiOutlinedInput-root': {
                    maxHeight: '2.5rem',
                    bgcolor: '#eeeff1',
                  },
                }}
                value={handlePartyShareFormik.values.initialPriceRangeMin}
                onChange={(e) => handlePriceValueChange(e, 'min')}
              />
            </Tooltip>
            <Typography variant="body2">{currency}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2">To</Typography>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title="ℹ️ Please enter a whole number between 0-99999."
              placement="top"
            >
              <TextField
                disabled={loading}
                type="number"
                name="initialPriceRangeMax"
                sx={{
                  width: '66px !important',
                  '& .MuiOutlinedInput-root': {
                    maxHeight: '2.5rem',
                    bgcolor: '#eeeff1',
                  },
                }}
                value={handlePartyShareFormik.values.initialPriceRangeMax}
                onChange={(e) => handlePriceValueChange(e, 'max')}
              />
            </Tooltip>
            <Typography variant="body2">{currency}</Typography>
          </Stack>
        </Stack>
        {renderAlert()}
      </>
    );

  return (
    <Stack spacing={2} px={matches ? 0 : 1.5}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          Options
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Customize your preferences for the Party Share automation."
            placement="top"
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Typography>

        <IconButton
          size="small"
          onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify icon={isOptionsExpanded ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />
        </IconButton>
      </Stack>

      {isOptionsExpanded && (
        <>
          {renderOptionSwitch(
            'shareContinuously',
            'Share items continuously in loops',
            'ℹ️ Enable this option to continuously share available items in loops during the party. After all items are shared once, looping begins, and sharing continues until the party ends or your share limit is reached.',
            shareContinuouslyEnabled
          )}
          {renderOptionSwitch(
            'shareSelectedCategories',
            'Share listings from selected categories during the open-theme evening party',
            'ℹ️ Enable this option to share listings from your selected categories during the open-themed evening party. This ensures that only items from your specified categories are shared throughout the party, and other listings will be skipped.',
            shareSelectedCategoriesEnabled
          )}
          {shareSelectedCategoriesEnabled && (
            <>
              {categoriesLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ml: '-36px !important',
                  }}
                >
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <CategoryRichTreeView
                  initialSelectedCategories={selectedCategories}
                  ruleIndex={0}
                  onSelectedCategoriesChange={handleCategoryChange}
                  updateAutomationSettings={false}
                />
              )}
            </>
          )}
          {renderPriceRangeSwitch()}
          {renderPriceRangeInputFields()}
        </>
      )}
    </Stack>
  );
};

export default PartyShareOptions;
