import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkBulkListingSourcesLoginStatus } from 'src/utils/checkListingSourcesLoginStatus';
import { PermissionCheckActionEnum } from 'src/hooks/useCheckPermission';
import { IPlatforms, setIsNotLoggedInDialogOpen } from 'src/store/dashboard/slices/myShopsSlice';
import {
  setCrosslistSourceLoginErrorPlatformsBulk,
  setIsExtensionUpdateDialogOpen,
  setIsManageSubscriptionsDialogOpen,
  setManageSubscriptionDialogData,
  setOpenAPIConnectionRequiredDialog,
  setOpenBulkCrosslistDialog,
  setOpenCrosslistDialog,
  setOpenDownloadExtensionDialog,
} from 'src/store/dashboard/slices/inventorySlice';
import { IListingSources } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import { AxiosResponse } from 'axios';
import { ActionType } from './usePlatformLoginStatusCheck';
import { IRootState } from 'src/store';
import { compareVersions } from 'src/auth/utils';
import { CompareVersionEnum } from 'src/auth/types';
import { BackgroundActionsEnum } from 'src/utils/ExtensionMessageChannel';
import { IS_SANDBOX } from 'src/config';
import { ListingStatus } from 'src/pages/dashboard/Inventory/types/newListingInterfaces';
import { toast } from 'react-toastify';

interface UseHandleCrosslistParams {
  listingData: any[];
  isExtensionConnected: boolean;
  checkPermission: (
    marketplace: string,
    action: PermissionCheckActionEnum
  ) => Promise<AxiosResponse<any, any>>;
  getLoginStatusOfMarketplace: (actionType: ActionType, platform: IPlatforms) => boolean;
  isBulk: boolean;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

const officialPlatforms = ['eBay (US)', 'eBay (CA)', 'eBay (AU)'];
const useHandleCrosslist = () => {
  const { appVersions, extensionVersion } = useSelector((state: IRootState) => state.auth);

  const dispatch = useDispatch();

  const handleCrosslist = useCallback(
    async ({
      listingData,
      isExtensionConnected,
      checkPermission,
      getLoginStatusOfMarketplace,
      isBulk,
      onSuccess,
      onError,
    }: UseHandleCrosslistParams) => {
      if (listingData.some((listing) => listing.status === ListingStatus.Draft)) {
        toast.error(
          'You cannot crosslist draft listings. Please publish or de-select your listings first.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          }
        );
        return;
      }

      const hasEveryListingMarketplaceSource = listingData.every(
        (listing) => Object.keys(listing.marketplaceSources).length > 0
      );

      if (!hasEveryListingMarketplaceSource) {
        toast.error(
          'Some selected listings have no listing link. Please make sure all selected listings have at least one marketplace source.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          }
        );
        return;
      }

      let canOpenCrosslistDialog = true;
      let notLoggedInPlatforms: IListingSources[] = [];
      let isConnectedOfficialPlatform = false;

      const processedListingData = checkBulkListingSourcesLoginStatus(
        listingData,
        getLoginStatusOfMarketplace
      );

      processedListingData.forEach(({ sources, oneSourceConnected }) => {
        if (oneSourceConnected) return;
        const notLoggedInSource = sources.find(
          (source) => !source.loginStatus && officialPlatforms.includes(source.platformName)
        );

        if (notLoggedInSource) {
          isConnectedOfficialPlatform = true;
          notLoggedInPlatforms.push(notLoggedInSource);
          canOpenCrosslistDialog = false;
        } else {
          sources.forEach((source) => {
            if (!source.loginStatus) {
              notLoggedInPlatforms.push(source);
              canOpenCrosslistDialog = false;
            }
          });
        }
      });

      try {
        const marketplace = Object.keys(listingData[0].marketplaceSources)[0];
        await checkPermission(marketplace, PermissionCheckActionEnum.CROSSLIST);

        if (!isExtensionConnected) {
          canOpenCrosslistDialog = false;
          dispatch(setOpenDownloadExtensionDialog(true));
        }
        const compareVersionsResult = compareVersions(
          appVersions.extension.version,
          extensionVersion ?? ''
        );

        if (
          !IS_SANDBOX &&
          isExtensionConnected &&
          compareVersionsResult === CompareVersionEnum.GREATER
        ) {
          canOpenCrosslistDialog = false;
          dispatch(setIsExtensionUpdateDialogOpen(true));
          return;
        }

        if (!isConnectedOfficialPlatform && notLoggedInPlatforms.length > 0) {
          dispatch(setIsNotLoggedInDialogOpen(true));
          dispatch(setCrosslistSourceLoginErrorPlatformsBulk(notLoggedInPlatforms));
        }

        if (isConnectedOfficialPlatform) {
          dispatch(setOpenAPIConnectionRequiredDialog(true));
        }

        if (canOpenCrosslistDialog) {
          dispatch(isBulk ? setOpenBulkCrosslistDialog(true) : setOpenCrosslistDialog(true));
        }

        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        dispatch(setManageSubscriptionDialogData(error));
        dispatch(setIsManageSubscriptionsDialogOpen(true));

        if (onError) {
          onError(error);
        }
      }
    },
    [dispatch, appVersions, extensionVersion]
  );

  return { handleCrosslist };
};

export default useHandleCrosslist;
