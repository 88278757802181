import axios from 'axios';
import { HOST_API_KEY } from '../config';
import * as Sentry from '@sentry/react';

// Custom event type for server errors
export const SERVER_ERROR_EVENT = 'primelister:server-error';

// localStorage key for custom API base URL
export const API_BASE_URL_KEY = 'PRIMELISTER_API_BASE_URL';

// Enum for critical endpoints that should trigger error events
export enum CriticalEndpoints {
  ACCOUNT = '/account',
}

export interface ServerErrorDetail {
  message: string;
  status: number;
  url: string;
}

// Function to get the current base URL
const getBaseUrl = (): string => {
  const customBaseUrl = localStorage.getItem(API_BASE_URL_KEY);
  return customBaseUrl || HOST_API_KEY;
};

// ----------------------------------------------------------------------
export const abortController = new AbortController();

const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  signal: abortController.signal,
});

const axiosNoAuth = axios.create({
  baseURL: getBaseUrl(),
  signal: abortController.signal,
});

// Response interceptor for handling errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      Sentry.captureException(error);
      // Server responded with error status (4xx, 5xx)
      const errorMessage = error.response.data?.message || 'Something went wrong';

      const currentEndpoint = error.config.url || '';
      const isCriticalEndpoint = Object.values(CriticalEndpoints).some(
        (endpoint) => currentEndpoint === endpoint
      );

      if (isCriticalEndpoint) {
        // Dispatch custom event for server error only for critical endpoints
        const errorDetail: ServerErrorDetail = {
          message: errorMessage,
          status: error.response.status,
          url: currentEndpoint || 'Unknown URL',
        };

        window.dispatchEvent(
          new CustomEvent(SERVER_ERROR_EVENT, {
            detail: errorDetail,
          })
        );
      }
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export const setHeader = (newToken: string) => {
  axiosInstance.defaults.headers.Authorization = `Bearer ${newToken}`;
};

export { axiosNoAuth };
export default axiosInstance;
