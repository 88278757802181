import {
  getGridNumericOperators,
  GridColumns,
  getGridDateOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from '@mui/x-data-grid-pro';
import { getNumericBetweenOperator } from 'src/pages/dashboard/Inventory/helpers/getNumericBetweenOperator';
import { getTimeStampBetweenOperator } from 'src/pages/dashboard/Inventory/helpers/getTimeBetweenOperator';

import { ITagData } from 'src/store/dashboard/slices/groupsAndTagsSlice';
import {
  DataGridActionsColumn,
  DataGridCreatedDateColumn,
  DataGridImageColumn,
  DataGridMarketplaceColumn,
  DataGridNameColumn,
  DataGridStatusColumn,
  DataGridGroupsColumn,
  DataGridPriceColumn,
  DataGridSKUColumn,
  DataGridTagsColumn,
} from './DataGridColumns';
import { getMarketplaceDisplayName, Marketplace } from '@beta.limited/primelister';
import { ListingStatus } from '../../../types/newListingInterfaces';
import { AutomationPlatform } from 'src/hooks/useAutomationPlatform';

export const getInventoryPlatformOptions = (allShops: Marketplace[] | null) => {
  const allMarketplaces = Object.values(Marketplace);

  if (!allShops) {
    return allMarketplaces
      .map((marketplace) => ({
        value: marketplace,
        label: getMarketplaceDisplayName(marketplace),
      }))
      .filter((option) => option.label)
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  const connectedEbayShops = allShops.filter((shop) =>
    shop.toString().toLowerCase().includes(AutomationPlatform.EBAY)
  );

  const nonEbayMarketplaces = allMarketplaces.filter(
    (marketplace) => !marketplace.toString().toLowerCase().includes(AutomationPlatform.EBAY)
  );

  return [...connectedEbayShops, ...nonEbayMarketplaces]
    .map((marketplace) => ({
      value: marketplace,
      label: getMarketplaceDisplayName(marketplace),
    }))
    .filter((option) => option.label)
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const gridColumns = (filteredData: ITagData[], allShops: Marketplace[] | null) => {
  const inventoryPlatformOptions = getInventoryPlatformOptions(allShops);
  const { numericBetweenOperator } = getNumericBetweenOperator();
  const { timeStampBetweenOperator } = getTimeStampBetweenOperator();

  const priceFilterOperators = [
    ...getGridNumericOperators().slice(0, 6),
    numericBetweenOperator,
    ...getGridNumericOperators().slice(6, 8),
  ];
  const addedFilterOperators = [
    ...getGridDateOperators(true).slice(0, 6),
    timeStampBetweenOperator,
    ...getGridDateOperators(true).slice(6, 8),
  ];
  //--is exactly
  const isExactlyOperator = getGridSingleSelectOperators().find(
    (filter) => filter.value?.toLowerCase() === 'isanyof'
  );
  isExactlyOperator!.label = 'is exactly';
  isExactlyOperator!.value = 'isExactly';
  //-- has all of
  const hasAllOfOperator = getGridSingleSelectOperators().find(
    (filter) => filter.value?.toLowerCase() === 'isanyof'
  );
  hasAllOfOperator!.label = 'has all of';
  hasAllOfOperator!.value = 'hasAllOf';
  //--has none of
  const hasNoneOfOperator = getGridSingleSelectOperators().find(
    (filter) => filter.value?.toLowerCase() === 'isanyof'
  );
  hasNoneOfOperator!.label = 'has none of';
  hasNoneOfOperator!.value = 'hasNoneOf';

  //-- has any of
  const hasAnyOfOperator = getGridSingleSelectOperators().find(
    (filter) => filter.value?.toLowerCase() === 'isanyof'
  );
  hasAnyOfOperator!.label = 'has any of';
  hasAnyOfOperator!.value = 'hasAnyOf';
  //-- is empty
  const isEmptyOperator = getGridStringOperators().find(
    (filter) => filter.value?.toLowerCase() === 'isempty'
  );

  //-- is not empty
  const isNotEmptyOperator = getGridStringOperators().find(
    (filter) => filter.value?.toLowerCase() === 'isnotempty'
  );

  const platformOperators = [
    hasAnyOfOperator,
    hasAllOfOperator,
    isExactlyOperator,
    hasNoneOfOperator,
  ];

  const tagsOperators = [
    hasAnyOfOperator,
    hasAllOfOperator,
    isExactlyOperator,
    hasNoneOfOperator,
    isEmptyOperator,
    isNotEmptyOperator,
  ];

  const tagsOptions: string[] = filteredData
    .map((item) => item.name?.trim())
    .filter(
      (name): name is string =>
        typeof name === 'string' && name.trim().length > 0 && /\S/.test(name)
    )
    .filter((name, index, names) => names.indexOf(name) === index) // remove duplicates
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));

  const columns: GridColumns = [
    {
      field: 'image',
      headerName: 'Image',
      pinnable: false,
      hideable: false,
      width: 64,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      filterable: false,
      valueGetter: (params) => params.value,
      renderCell: (params) => <DataGridImageColumn url={params.value} />,
    },
    {
      field: 'title',
      headerName: 'Name',
      pinnable: false,
      hideable: false,
      width: 500,
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params) => params.value,
      renderCell: (params) => (
        <DataGridNameColumn title={params.value.title} id={params.value.id} />
      ),
    },
    {
      field: 'sku',
      headerName: 'SKU',
      pinnable: false,
      hideable: false,
      width: 100,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value,
      renderCell: (params: any) => <DataGridSKUColumn sku={params.value} />,
    },
    {
      field: 'price',
      type: 'number',
      headerName: 'Price',
      pinnable: false,
      hideable: false,
      headerAlign: 'center',
      align: 'center',
      filterOperators: priceFilterOperators,
      valueGetter: (params) => params.value,
      renderCell: (params) => <DataGridPriceColumn price={params.value} />,
    },
    {
      field: 'refs',
      type: 'singleSelect',
      valueOptions: inventoryPlatformOptions.map((option) => option.label),
      headerName: 'Marketplace',
      pinnable: false,
      hideable: false,
      width: 125,
      //@ts-ignore
      filterOperators: platformOperators,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value,
      renderCell: (params: any) => <DataGridMarketplaceColumn marketplaceSources={params.value} />,
    },
    {
      field: 'groups',
      headerName: 'Group',
      pinnable: false,
      hideable: false,
      sortable: true,
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value,
      renderCell: (params) => (
        <DataGridGroupsColumn rowData={params.row.added} groups={params.value} />
      ), ///Groups
    },
    {
      field: 'listingTags',
      type: 'singleSelect',
      valueOptions: tagsOptions,
      headerName: 'Tags',
      pinnable: false,
      hideable: false,
      sortable: true,
      width: 120,
      cellClassName: 'datagrid-cell-tags',
      //@ts-ignore
      filterOperators: tagsOperators,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value,
      renderCell: (params) => <DataGridTagsColumn rowData={params.row} tags={params.value} />, ///Tags
    },
    {
      field: 'status',
      type: 'singleSelect',
      valueOptions: [ListingStatus.Listed, ListingStatus.Sold, ListingStatus.Draft],
      headerName: 'Status',
      pinnable: false,
      cellClassName: 'datagrid-cell-status',
      hideable: false,
      width: 100,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value,
      renderCell: (params) => <DataGridStatusColumn rowData={params.row} status={params.value} />,
    },
    {
      field: 'added',
      type: 'dateTime',
      headerName: 'Created At',
      pinnable: false,
      hideable: false,
      width: 130,
      headerAlign: 'center',
      align: 'center',
      filterOperators: addedFilterOperators,
      valueGetter: (params) => params.value,
      renderCell: (params) => <DataGridCreatedDateColumn itemData={params.row.added} />,
    },
  ];
  return columns;
};
