import { getGridDateOperators, GridColumns } from '@mui/x-data-grid-pro';
import { getTimeStampBetweenOperator } from 'src/pages/dashboard/Tasks/helpers/getTimeBetweenOperator';
import DataGridActionsColumn from './DataGridColumns/DataGridActionsColumn';
import DataGridCreatedDateColumn from './DataGridColumns/DataGridCreatedDateColumn';
import DataGridExecuteAtColumn from './DataGridColumns/DataGridExecutedAtColumn';
import DataGridImageColumn from './DataGridColumns/DataGridImageColumn';
import DataGridMarketplaceColumn from './DataGridColumns/DataGridMarketplaceColumn';
import DataGridNameColumn from './DataGridColumns/DataGridNameColumn';
import DataGridResultColumn from './DataGridColumns/DataGridResultColumn';
import DataGridTypeColumn, { TASK_TYPE } from './DataGridColumns/DataGridTypeColumn';
import { getMarketplaceDisplayName, Marketplace } from '@beta.limited/primelister';
import { AutomationPlatform } from 'src/hooks/useAutomationPlatform';

export const getTaskPlatformOptions = (allShops: Marketplace[] | null) => {
  const allMarketplaces = Object.values(Marketplace);

  if (!allShops) {
    return allMarketplaces
      .map((marketplace) => ({
        value: marketplace,
        label: getMarketplaceDisplayName(marketplace),
      }))
      .filter((option) => option.label)
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  const connectedEbayShops = allShops.filter((shop) =>
    shop.toString().toLowerCase().includes(AutomationPlatform.EBAY)
  );

  const nonEbayMarketplaces = allMarketplaces.filter(
    (marketplace) => !marketplace.toString().toLowerCase().includes(AutomationPlatform.EBAY)
  );

  return [...connectedEbayShops, ...nonEbayMarketplaces]
    .map((marketplace) => ({
      value: marketplace,
      label: getMarketplaceDisplayName(marketplace),
    }))
    .filter((option) => option.label)
    .sort((a, b) => a.label.localeCompare(b.label));
};

function formatDate(dateString: string) {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US');
}

function formatedTypeOptions() {
  const uniqueTypeNames = TASK_TYPE.map((item) => item.name)
    .filter((item, index, self) => self.findIndex((i) => i === item) === index)
    .filter((item) => item !== 'Delete from Inventory' && item !== 'Organize Closet')
    .map((item) => (item === 'Offer to Likers' ? 'Offer' : item))
    .sort();
  return uniqueTypeNames;
}

const { timeStampBetweenOperator } = getTimeStampBetweenOperator();

const dateTimeFilterOperators = [
  ...getGridDateOperators(true).slice(0, 6),
  timeStampBetweenOperator,
  ...getGridDateOperators(true).slice(6, 8),
];

export const getColumns = (allShops: Marketplace[] | null): GridColumns => {
  const taskPlatformOptions = getTaskPlatformOptions(allShops);

  return [
    {
      field: 'image',
      headerName: 'Image',
      width: 75,
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      valueGetter: (params) => params.value.image,
      renderCell: (params) => <DataGridImageColumn url={params.value} />,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 500,
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params) => params.value.name,
      renderCell: (params) => <DataGridNameColumn name={params.value} />,
    },
    {
      field: 'typeName',
      headerName: 'Type',
      headerAlign: 'center',
      minWidth: 200,
      align: 'center',
      type: 'singleSelect',
      valueOptions: formatedTypeOptions(),
      valueGetter: (params) => params.value.typeName,
      renderCell: (params) => <DataGridTypeColumn typeName={params.value} />,
    },
    {
      field: 'marketplace',
      headerName: 'Marketplace',
      width: 140,
      headerAlign: 'center',
      align: 'center',
      type: 'singleSelect',
      valueOptions: taskPlatformOptions,
      valueFormatter: ({ value }) => {
        const option = taskPlatformOptions.find((opt) => opt.value === value);
        return option ? option.label : '';
      },
      valueGetter: (params) => params.value.marketplace,
      renderCell: (params) => <DataGridMarketplaceColumn marketplace={params.value} />,
    },
    {
      field: 'taskResult',
      headerName: 'Result',
      width: 140,
      headerAlign: 'center',
      align: 'center',
      type: 'singleSelect',
      valueOptions: ['Succeeded', 'Missed', 'Failed'],
      valueGetter: (params) => params.value.result,
      renderCell: (params) => <DataGridResultColumn result={params.value} />,
    },
    {
      field: 'createdDate',
      headerName: 'Created At',
      width: 200,
      headerAlign: 'right',
      align: 'right',
      type: 'dateTime',
      filterOperators: dateTimeFilterOperators,
      valueGetter: (params) => params.value.createdDate,
      valueFormatter: (params) => formatDate(params.value.createdDate),
      renderCell: (params) => <DataGridCreatedDateColumn createdDate={params.value} />,
    },
    {
      field: 'Actions',
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      width: 80,
      valueGetter: (params) => params.value,
      renderCell: (params) => <DataGridActionsColumn taskItemData={params.row.actions} />,
    },
  ];
};
