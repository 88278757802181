import { getExpiresDateForCookies } from '../auth/utils';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import cookie from 'react-cookies';
import { getTaskErrorList } from 'src/store/dashboard/slices/tasksSlice';
import { setInitializedState, setUserBlockState } from 'src/store/dashboard/slices/authSlice';
import { isLoggedIn } from 'src/utils/isLoggedIn';
import {
  accountDetailRequest,
  getBillingInvoiceListRequest,
  getBillingSubscriptionsRequest,
  getCardsInfoRequest,
  setCurrentTab,
  setInitialLoading,
  setInvoicesRequestLimit,
} from 'src/store/dashboard/slices/accountSlice';
import { useNavigate } from 'react-router';
import { updateCrispChatSession } from 'src/utils/crispChatHelpers';
import useResponsive from './useResponsive';
import { IRootState } from 'src/store';
const useStripeRedirectedUser = () => {
  const dispatch = useDispatch();
  const { blockState, userId } = isLoggedIn();
  const navigate = useNavigate();
  const clearBlockState = () => {
    dispatch(setUserBlockState(0));
    let userData = cookie.load('userData');
    userData.blockState = 0;
    cookie.save('userData', userData, { path: '/', expires: getExpiresDateForCookies() });
  };
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const { isAccountLoaded } = useSelector((state: IRootState) => state.account);
  const isMobileOrApp = useResponsive('down', 'sm') || isPrimeListerMobileApp;

  const initialRequestForBillingTab = (referrer: string | null, referrerMessage: string | null) => {
    if (referrer) {
      toast.success(referrerMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }

    if (referrerMessage && !referrerMessage.includes('payment')) {
      dispatch(setInitialLoading(true));
      setTimeout(() => {
        dispatch(setInvoicesRequestLimit(10));
        dispatch(setInitialLoading(true));
        dispatch(getBillingSubscriptionsRequest());
        dispatch(getBillingInvoiceListRequest(10));
        dispatch(getCardsInfoRequest());
        if (!isAccountLoaded) {
          dispatch(accountDetailRequest({ navigate, isMobileOrApp }));
        }
      }, 3000);
    } else {
      dispatch(setInvoicesRequestLimit(10));
      dispatch(setInitialLoading(true));
      dispatch(getBillingSubscriptionsRequest());
      dispatch(getBillingInvoiceListRequest(10));
      dispatch(getCardsInfoRequest());
      if (!isAccountLoaded) {
        dispatch(accountDetailRequest({ navigate, isMobileOrApp }));
      }
    }
  };

  useEffect(() => {
    const referrer = new URLSearchParams(window.location.search).get('referrer');
    dispatch(getTaskErrorList());

    let referrerMessage;
    switch (referrer) {
      case 'subscription_update_confirm':
        referrerMessage = 'Your subscription has been successfully updated.';
        clearBlockState();
        break;
      case 'payment_method_update':
        referrerMessage = 'Your card succesfully added';
        dispatch(setInitializedState({ blockState, userId }));
        break;
      case 'subscription_create_confirm':
        referrerMessage = 'Your subscription succesfully started';
        clearBlockState();
        break;
      default:
        referrerMessage = null;
        dispatch(setInitializedState({ blockState, userId }));
        break;
    }

    initialRequestForBillingTab(referrer, referrerMessage);
    const updatedUrl = window.location.href.replace(`?referrer=${referrer}`, '');
    const isInUserPage = window.location.href.includes('user');
    if (referrer && isInUserPage) {
      dispatch(setCurrentTab('billing'));
    } else {
      window.history.replaceState({}, '', updatedUrl);
    }
  }, []);
};

export default useStripeRedirectedUser;
