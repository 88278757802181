import React from 'react';
import { Box, Card, Typography, Button, Stack, useTheme } from '@mui/material';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsAffiliatePayoutDialogOpen,
  setIsPayoutSubmittedDialogOpen,
  setIsPayoutSubscriptionExpiredDialogOpen,
  updatePayoutRequestDateRequest,
} from 'src/store/dashboard/slices/accountSlice';
import AffiliatePayoutDialog from './dialogs/AffiliatePayoutDialog';
import { IRootState } from 'src/store';
import { toast } from 'react-toastify';
import { differenceInDays } from 'date-fns';
import PayoutSubmittedDialog from './dialogs/PayoutSubmittedDialog';
import PayoutSubscriptionExpiredDialog from './dialogs/PayoutSubscriptionExpiredDialog';

interface AffiliateCardProps {
  title: string;
  content: string;
  buttonText: string;
  onButtonClick: () => void;
  showSecondButton?: boolean;
  secondButtonText?: string;
  onSecondButtonClick?: () => void;
}

const AffiliateCard: React.FC<AffiliateCardProps> = ({
  title,
  content,
  buttonText,
  onButtonClick,
  showSecondButton,
  secondButtonText,
  onSecondButtonClick,
}) => (
  <Card
    sx={{
      p: 3,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%', // Make the card fill the height of its container
      justifyContent: 'space-between', // Distribute content evenly
    }}
  >
    <Typography
      sx={{ mb: 1, color: 'text.primary', fontWeight: 600, lineHeight: '22px', fontSize: '14px' }}
    >
      {title}
    </Typography>
    <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary', lineHeight: '22px' }}>
      {content?.length > 80 ? `${content?.substring(0, 80)}...` : content}
    </Typography>
    <Stack direction="row" spacing={2}>
      <Button
        size="small"
        variant="outlined"
        color="inherit"
        onClick={onButtonClick}
        sx={{
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
        {buttonText}
      </Button>
      {showSecondButton && secondButtonText && onSecondButtonClick && (
        <Button
          size="small"
          variant="outlined"
          color="inherit"
          onClick={onSecondButtonClick}
          sx={{
            width: 'fit-content',
            height: 'fit-content',
          }}
        >
          {secondButtonText}
        </Button>
      )}
    </Stack>
  </Card>
);

const AffiliateCards: React.FC = () => {
  const { copy } = useCopyToClipboard();
  const {
    affiliateSummary,
    userAccountDetail,
    affiliateBalanceHistoryPage,
    affiliateBalanceHistoryPageSize,
    billingSubscriptionList,
  } = useSelector((state: IRootState) => state.account);

  const extractReferrerCode = (url: string): string => {
    try {
      const urlObj = new URL(url);
      const referrerCode = urlObj.searchParams.get('referrerCode');
      return referrerCode || '';
    } catch (error) {
      return '';
    }
  };

  const handleCopyLink = async () => {
    try {
      await copy(affiliateSummary?.affiliateUrl!);
      toast.success('Affiliate link copied', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      toast.error('Failed to copy affiliate link', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const handleCopyAffiliateCode = async () => {
    try {
      const referrerCode = extractReferrerCode(affiliateSummary?.affiliateUrl!);
      if (!referrerCode) {
        throw new Error('No referrer code found');
      }
      await copy(referrerCode);
      toast.success('Affiliate code copied', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      toast.error('Failed to copy affiliate code', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const dispatch = useDispatch();

  const handlePayoutToPaypal = () => {
    const currentDate = new Date();
    const isSubscriptionsExpired = billingSubscriptionList.every(
      (subscription) => new Date(subscription?.expires) < currentDate
    );

    if (isSubscriptionsExpired) {
      dispatch(setIsPayoutSubscriptionExpiredDialogOpen(true));
      // Handle the case where both subscriptions are expired
      return;
    }
    if ((affiliateSummary?.availableToPayout ?? 0) < 50) {
      dispatch(setIsAffiliatePayoutDialogOpen(true));
      return;
    }

    const lastPayoutDate = affiliateSummary?.lastPayoutRequestDate
      ? new Date(affiliateSummary.lastPayoutRequestDate)
      : null;
    const daysSinceLastPayout = lastPayoutDate
      ? differenceInDays(new Date(), lastPayoutDate)
      : Infinity;

    if (daysSinceLastPayout < 25) {
      dispatch(setIsPayoutSubmittedDialogOpen(true));
    } else {
      window.Tally.openPopup('w89Zrn', {
        hiddenFields: {
          email: userAccountDetail.email,
        },
        layout: 'modal',
        width: 700,
        onSubmit: (payload: any) => {
          // Form was submitted, use the answers payload in your application
          dispatch(
            updatePayoutRequestDateRequest({
              affiliateBalanceHistoryPage,
              affiliateBalanceHistoryPageSize,
            })
          );
        },
      });
    }
  };

  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', gap: 2, mt: '24px', flexWrap: 'wrap' }}>
      <Box sx={{ width: 'calc(50% - 8px)', [theme.breakpoints.down('md')]: { width: '100%' } }}>
        <AffiliateCard
          title="Your affiliate Link"
          content={affiliateSummary?.affiliateUrl!}
          buttonText="Copy your affiliate Link"
          onButtonClick={handleCopyLink}
          showSecondButton
          secondButtonText="Copy Affiliate Code"
          onSecondButtonClick={handleCopyAffiliateCode}
        />
      </Box>
      <Box sx={{ width: 'calc(50% - 8px)', [theme.breakpoints.down('md')]: { width: '100%' } }}>
        <AffiliateCard
          title={`Available to pay out: $${affiliateSummary?.availableToPayout ?? 0}`}
          content={`Total reward: $${affiliateSummary?.totalRewardAmount ?? 0}`}
          buttonText="Payout to PayPal"
          onButtonClick={handlePayoutToPaypal}
        />
      </Box>
      <AffiliatePayoutDialog />
      <PayoutSubmittedDialog />
      <PayoutSubscriptionExpiredDialog />
    </Box>
  );
};

export default AffiliateCards;
