import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';

import {
  setDeletePopup,
  setDeleteType,
  setOpenBulkPopoverMenu,
  setOpenExtensionCommonDialog,
  setExtensionActionType,
  setOpenInventoryAddGroupDialog,
  setOpenInventoryAddTagDialog,
  setOpenInventoryUpdateStatusDialog,
  setSelectedStatusToUpdate,
  setOpenInventoryMergeListingsDialog,
  setOpenDownloadExtensionDialog,
  setOpenBulkCrosslistDialog,
  setOpenAPIConnectionRequiredDialog,
  setCrosslistSourceLoginErrorPlatformsBulk,
  setIsManageSubscriptionsDialogOpen,
  setManageSubscriptionDialogData,
  setIsRemoveGroupsDialogOpen,
  setIsRemoveTagsDialogOpen,
} from 'src/store/dashboard/slices/inventorySlice';
import { setIsNotLoggedInDialogOpen } from 'src/store/dashboard/slices/myShopsSlice';
import { checkBulkListingSourcesLoginStatus } from 'src/utils/checkListingSourcesLoginStatus';
import usePlatformLoginStatusCheck from './usePlatformLoginStatusCheck';
import { IListingSources } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import useCheckPermission, {
  PermissionCheckActionEnum,
  permissionCheckActionMapping,
} from './useCheckPermission';
import useHandleCrosslist from './useHandleCrosslist';
import useIsDocumentLoaded from './useIsDocumentLoaded';
import { toast } from 'react-toastify';
import { ListingStatus } from 'src/pages/dashboard/Inventory/types/newListingInterfaces';

export interface IBulkMenuActionProps {
  text?: string;
  icon: string;
  handleClick?: () => void;
  errorColor?: boolean;
  maxWidth?: string;
  iconWidth?: string;
  iconHeight?: string;
  buttonWidth?: string;
  divider?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export default function useInventoryBulkActionItems() {
  const { isExtensionConnected, hasCheckedConnection, isExtensionConnectedResultLoading } =
    useSelector((state: IRootState) => state.home);
  const myShopsAPITable = useSelector((state: IRootState) => state.myShopsAPITable);
  const { crosslistSourceLoginErrorPlatforms, bulkActionsListingList } = useSelector(
    (state: IRootState) => state.inventory
  );
  const { checkPermission } = useCheckPermission();
  const { getLoginStatusOfMarketplace } = usePlatformLoginStatusCheck();

  const dispatch = useDispatch();
  const handlePopoverCLose = () => dispatch(setOpenBulkPopoverMenu(null));

  const isDocumentLoaded = useIsDocumentLoaded();

  const isLoading = !isDocumentLoaded || !hasCheckedConnection || isExtensionConnectedResultLoading;

  const handleExtensionActionSelection = async (index: number) => {
    if (isExtensionConnected) {
      try {
        const hasEveryListingMarketplaceSource = bulkActionsListingList.every(
          (listing) => Object.keys(listing.marketplaceSources).length > 0
        );

        const hasAtLeastOneDraftListing = bulkActionsListingList.some(
          (listing) => listing.status === ListingStatus.Draft
        );
        if (hasAtLeastOneDraftListing) {
          toast.error(
            'You cannot perform this action on draft listings. Please publish or de-select your listings first.',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            }
          );
          return;
        }

        if (!hasEveryListingMarketplaceSource) {
          toast.error(
            'Some selected listings have no listing link. Please make sure all selected listings have at least one marketplace source.',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            }
          );
          return;
        }

        const marketplace = Object.keys(bulkActionsListingList[0].marketplaceSources)[0];
        const taskType = permissionCheckActionMapping[index] as PermissionCheckActionEnum;
        await checkPermission(marketplace, taskType);

        dispatch(setOpenExtensionCommonDialog(true));
        dispatch(setExtensionActionType(index));
      } catch (error) {
        dispatch(setManageSubscriptionDialogData(error));
        dispatch(setIsManageSubscriptionsDialogOpen(true));
      }
      handlePopoverCLose();
    } else {
      handlePopoverCLose();
      dispatch(setOpenDownloadExtensionDialog(true));
    }
  };

  const { handleCrosslist } = useHandleCrosslist();

  const handleInventoryDelete = () => {
    dispatch(setDeletePopup(true));
    dispatch(setDeleteType(1));
    handlePopoverCLose();
  };

  const handleAddGroup = () => {
    dispatch(setOpenInventoryAddGroupDialog(true));
    handlePopoverCLose();
  };

  const handleRemoveGroups = () => {
    dispatch(setIsRemoveGroupsDialogOpen(true));
    handlePopoverCLose();
  };
  const handleAddTags = () => {
    dispatch(setOpenInventoryAddTagDialog(true));
    handlePopoverCLose();
  };

  const handleRemoveTags = () => {
    dispatch(setIsRemoveTagsDialogOpen(true));
    handlePopoverCLose();
  };

  const handleStatus = (status: string) => {
    dispatch(setOpenInventoryUpdateStatusDialog(true));
    dispatch(setSelectedStatusToUpdate(status));
    handlePopoverCLose();
  };

  const handleMerge = () => {
    dispatch(setOpenInventoryMergeListingsDialog(true));
    handlePopoverCLose();
  };

  const actionList1: IBulkMenuActionProps[] = [
    {
      text: 'Cross-list',
      icon: 'ph:arrows-left-right-bold',
      handleClick: () =>
        handleCrosslist({
          listingData: bulkActionsListingList,
          isBulk: true,
          checkPermission,
          getLoginStatusOfMarketplace,
          isExtensionConnected,
          onSuccess: handlePopoverCLose,
        }),
      maxWidth: '147px',
      iconWidth: '23px',
      iconHeight: '23px',
      disabled: !isDocumentLoaded || isLoading,
      loading: isLoading,
    },
    {
      text: 'Re-list',
      icon: 'el:retweet',
      handleClick: () => handleExtensionActionSelection(2),
      maxWidth: '128px',
      iconWidth: '26.5px',
      iconHeight: '25.5px',
      disabled: !isDocumentLoaded || isLoading,
      loading: isLoading,
    },
    {
      text: 'Duplicate',
      icon: 'ion:copy',
      handleClick: () => handleExtensionActionSelection(1),
      maxWidth: '145px',
      iconWidth: '16px',
      iconHeight: '16px',
      disabled: !isDocumentLoaded || isLoading,
      loading: isLoading,
    },
  ];

  const actionList2: IBulkMenuActionProps[] = [
    {
      text: 'Delist',
      icon: 'mdi:file-remove',
      handleClick: () => handleExtensionActionSelection(5),
      maxWidth: '119px',
      iconWidth: '18.69px',
      iconHeight: '22.27px',
      buttonWidth: '68px',
      disabled: !isDocumentLoaded || isLoading,
      loading: isLoading,
    },
    {
      text: 'Delete from Marketplace',
      icon: 'ant-design:delete-filled',
      handleClick: () => handleExtensionActionSelection(0),
      maxWidth: '246px',
      iconWidth: '21.9px',
      iconHeight: '20.6px',
      buttonWidth: '60px',
      disabled: !isDocumentLoaded || isLoading,
      loading: isLoading,
    },
  ];

  const popoverActionsList: IBulkMenuActionProps[] = [
    {
      text: 'Add to a Group',
      icon: '/icons/bulkActionsPopoverMenuIcons/addGroupIcon.svg',
      handleClick: handleAddGroup,
    },
    {
      text: 'Remove Group(s)',
      disabled: !bulkActionsListingList.some((listing) => listing.groups.length > 0),
      icon: '/icons/bulkActionsPopoverMenuIcons/removeGroupIcon.svg',
      handleClick: handleRemoveGroups,
    },
    {
      icon: '',
      divider: true,
    },
    {
      text: 'Add to a Tag',
      icon: '/icons/bulkActionsPopoverMenuIcons/addTagIcon.svg',
      handleClick: handleAddTags,
    },
    {
      text: 'Remove Tag(s)',
      disabled: !bulkActionsListingList.some((listing) => listing.tags.length > 0),
      icon: '/icons/bulkActionsPopoverMenuIcons/removeTagIcon.svg',
      handleClick: handleRemoveTags,
    },
    {
      icon: '',
      divider: true,
    },
    {
      text: 'Merge Listings',
      icon: '/icons/bulkActionsPopoverMenuIcons/mergeListingIcon.svg',
      handleClick: handleMerge,
    },
    {
      text: 'Mark as Listed',
      icon: '/icons/bulkActionsPopoverMenuIcons/markListedIcon.svg',
      handleClick: () => handleStatus('Listed'),
    },
    {
      text: 'Mark as Sold',
      icon: '/icons/bulkActionsPopoverMenuIcons/markSoldIcon.svg',
      handleClick: () => handleStatus('Sold'),
    },
    {
      icon: '',
      divider: true,
    },
    {
      text: 'Delete from Inventory',
      icon: '/icons/bulkActionsPopoverMenuIcons/deleteIcon.svg',
      handleClick: handleInventoryDelete,
      errorColor: true,
      disabled: !isDocumentLoaded,
    },
  ];

  return {
    actionList1,
    actionList2,
    popoverActionsList,
    isLoading,
  };
}
